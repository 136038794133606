export interface Member {
  name: string;
  email: string;
  phone: string;
  phoneCountryCode: string;
  role?: string;
}

export interface DeliveryInfo {
  targetDate?: Date | string;
  technician?: string;
}

// public string? Department { get; set; }
//     public string? Description { get; set; }
//     public string? Address { get; set; }
//     public string? City { get; set; }
//     public string? State { get; set; }
//     public string? Zip { get; set; }

export interface Store {
  department?: string;
  description?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
}
export interface Customer {
  name?: string;
}
export interface BaseOrderLine {
  id: string;
  siteId: string;
  orderNum: string;
  lineId: string;
  orderLineId?: string;
  totalPayments?: number;
  remainingBalance?: number;
  currentAmountDue?: number;
  members: Member[];
  status?: 'OrderConfirmation' | 'OrderPrep' | 'Production' | 'QualityCheck' | 'Delivery_install' | 'Completion';
  deliveryInfo: DeliveryInfo;
  customerName: string;
  productName: string;
  width: string;
  length: string;
  configurationId?: string;
  store?: Store;
  customer?: Customer;
  productItemType?: string;
  productId?: string;
  type?: 'Regular' | 'Stock' | 'Preowned';
  pmtType?: string;
}
export const defaultBaseOrderLine = {
  id: '',
  siteId: '',
  orderNum: '',
  lineId: '',
  orderLineId: undefined,
  totalPayments: undefined,
  remainingBalance: undefined,
  currentAmountDue: undefined,
  members: [],
  status: undefined,
  deliveryInfo: {
    targetDate: undefined,
    technician: undefined,
  },
  customerName: '',
  productName: '',
  width: '',
  length: '',
};
