import { OrderLine } from 'shared/models/order-line.model';

/**
 * This function checks if an orderLine is 3D orderLine (orderNum is different than '', and value starts with 'IN').
 */
const is3dOrderLine = (orderLine: OrderLine): boolean | undefined => {
  const { orderNum } = orderLine;
  if (orderNum === '' || orderNum === null || orderNum === undefined) return;
  return orderNum.startsWith('IN', 0) || orderLine.type === 'Preowned';
};

export default is3dOrderLine;
